<template>
    <div>
        <card-header title="Registration" icon="fa-suitcase" :subheader="registration.title"/>
        <card-toolbar>
            <button @click="$openCard('edit-registration', {registrationId: registration.id}, card);"><i class="fas fa-pencil mr-2"/>Edit Registration</button>
            <button @click="markReady()"><i class="fas fa-tasks mr-2"/>Mark as Ready</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <container-list-item padded class=" pb-1 mb-0 content" v-if="registration.deleted">
                    <h5 class="has-text-danger"><i class="fas fa-exclamation-triangle mr-2"></i>This registration has been deleted.</h5>
                </container-list-item>
                <trip-list-item :card="card" :trip="trip"/>
                <subheader-list-item title="Participants" icon="fa-users"/>
                <customer-list-item
                    :card="card"
                    :user="registration.user"
                    :active="child && child.definition.component === 'customer' && registration.user.id && child.props.userId === registration.user.id.toString()"
                    @click="$openCard('customer', {userId: registration.user.id}, card)"
                />
                <customer-list-item
                    :card="card"
                    :user="registration.second_user"
                    :active="child && child.definition.component === 'customer' && registration.second_user.id && child.props.userId === registration.second_user.id.toString()"
                    @click="$openCard('customer', {userId: registration.second_user.id}, card)"
                    v-if="registration.second_user"
                />

                <card-list-item v-if="registration.second_lastname">
                    <template v-slot:icon><i class="far fa-fw fa-share-all"></i></template>
                    <template v-slot:content>
                        <div class="tile-title">{{ registration.second_lastname }}, {{ registration.second_firstname }}</div>
                        <div class="meta"><i class="far fa-fw fa-envelope mr-2"></i>{{registration.second_email}}</div>
                    </template>
                </card-list-item>


                <subheader-list-item title="Registration Details" icon="fa-list"/>
                <data-row title="Type(s)">
                    {{registration.type | capitalize}}
                    <span v-if="registration.second_id">, {{registration.second_type | capitalize}}</span>
                </data-row>
                <data-row title="Registered">{{registration.created_at | moment('MMMM Do, YYYY, h:mm A')}}</data-row>
                <data-row title="Plan Paid" v-if="registration.payment_plan">{{registration.payment_plan.paid | currency}} of {{registration.payment_plan.total | currency}}</data-row>

                <data-row title="Status">
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{['fa-check status-check']: registration.itinerary_complete, 'fa-ban has-text-warning': !registration.itinerary_complete}"></i>Itinerary</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{['fa-check status-check']: registration.flights_complete, 'fa-ban has-text-warning': !registration.flights_complete}"></i>Flights</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{['fa-check status-check']: registration.addons_complete, 'fa-ban has-text-warning': !registration.addons_complete}"></i>Add-Ons</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{['fa-check status-check']: registration.questions_complete, 'fa-ban has-text-warning': !registration.questions_complete}"></i>Questions</span>
                    <span class="mr-4" style="white-space: nowrap"><i class="fas mr-1" :class="{['fa-check status-check']: registration.ready_to_travel, 'fa-ban has-text-warning': !registration.ready_to_travel}"></i>Ready to Travel</span>
                </data-row>


                <template v-if="registration.payment_plan">
                    <separator-list-item/>
                    <navigation-list-item
                        title="Itemized Registration"
                        icon="fa-file-invoice"
                        @click="$openCard('itemized', {planId: registration.payment_plan.id, regId: registration.id}, card)"
                    />
                    <navigation-list-item
                        title="Payment Plan"
                        icon="fa-credit-card-front"
                        @click="$openCard('payment-plan', {planId: registration.payment_plan.id, regId: registration.id}, card)"
                    />
                </template>
                <template v-else>
                    <data-row title="Total">{{registration.total | currency}}</data-row>
                </template>
                <separator-list-item/>

                <navigation-list-item
                    title="Notes"
                    icon="fa-sticky-note"
                    :active="child && child.definition.component === 'reg-notes'"
                    :badge="registration.notes.length"
                    @click="$openCard('reg-notes', {registrationId: registration.id}, card)"
                />

                <navigation-list-item
                    title="Registration Days"
                    icon="fa-calendar-check"
                    @click="$openCard('reg-days', {tripId: registration.trip_id, registrationId: registration.id}, card)"
                    :active="child && child.definition.component === 'reg-days'"
                />

                <task-navigation-list-item
                    title="Personal Itinerary"
                    icon="fa-calendar-alt"
                    :active="child && child.definition.component === 'itinerary'"
                    @click="$openCard('itinerary', {tripId: registration.trip_id, registrationId: registration.id}, card)"
                    :tasks="registration.tasks.events"
                />

                <task-navigation-list-item
                    title="Flight Information"
                    icon="fa-plane"
                    :badge="registration.flights.length"
                    :tasks="registration.tasks.flights"
                    @click="$openCard('flights', {registrationId: registration.id}, card)"
                    :active="child && child.definition.component === 'flights'"
                />

                <task-navigation-list-item
                    title="Hotel Information"
                    icon="fa-hotel"
                    @click="$openCard('reg-hotels', {registrationId: registration.id}, card)"
                    :active="child && child.definition.component === 'reg-hotels'"
                />

                <task-navigation-list-item
                    title="Question Responses"
                    icon="fa-clipboard-list-check"
                    :active="child && child.definition.component === 'responses'"
                    @click="$openCard('responses', {registrationId: registration.id}, card)"
                    :tasks="registration.questions_complete === 0"
                />
                <task-navigation-list-item
                    title="Add-Ons"
                    icon="fa-layer-plus"
                    :active="child && child.definition.component === 'addons'"
                    @click="$openCard('addons', {tripId: registration.trip_id, registrationId: registration.id}, card)"
                    :badge="registration.addons.length"
                    :tasks="registration.tasks.addons"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DataRow from "../../TIER/components/DataRow";
    import CustomerListItem from "../../components/CustomerListItem";
    import TaskNavigationListItem from "@/components/TaskNavigationListItem";
    import CardListItem from "@/TIER/components/CardListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import {mapActions} from "vuex";

    export default {
        name: "Registration",
        props: ['card', 'child', 'props'],
        components: {ContainerListItem, CardListItem, TaskNavigationListItem, CustomerListItem, DataRow, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                trip: {},
                registration: {
                    trip: {},
                    user: {},
                    tasks: {},
                    addons: [],
                    flights: [],
                    notes: []
                }
            }
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadRegistration: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId, {force}).then(response => {
                    this.registration = response.data;
                    this.$emit('title', this.registration.user.first_name + ' ' + this.registration.user.last_name);

                    http.get('/api/trips/' + this.registration.trip_id).then(response => {
                        this.trip = response.data;
                        this.$emit('loading', false);
                    });
                });
            },
            reload() {
                this.loadRegistration(true);
            },
            markReady() {
                if (confirm('Are you sure you mark this registration complete and ready to travel?')) {
                    http.post('/api/registrations/' + this.registration.id + '/complete').then(response => {
                        this.loadRegistration(true);
                        this.$reloadCard('registrations');
                        this.updateTasks();
                    });
                }
            }
        },
        watch: {
            'props.registrationId': function() {
                this.loadRegistration(true);
            }
        },
        mounted() {
            this.loadRegistration(true);
        }
    };
</script>

<style lang="scss" scoped>
    .tile-title {
        color: darken($t-primary, 10);
        font-size: .9rem;
        font-weight: 700;
    }

    .status-check {
        color: $t-primary;
    }
</style>
